import styled from 'styled-components';

interface SectionContainerProps {
  background: string;
}

export const SectionContainer = styled.div<SectionContainerProps>`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: url(${props => props.background}) no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

export const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  padding: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const InfoSection = styled.div`
  flex: 1;
  margin-right: 40px;
  margin-top: -100px;
  text-align: left;

  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 30px;
  }
`;

export const InfoTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
`;

export const ContactSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const ContactImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 15px;
`;

export const ContactText = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

export const InfoIcon = styled.span`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export const InfoText = styled.p`
  font-size: 1.2rem;
  margin: 0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
`;

export const NavSection = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const NavButton = styled.button`
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  padding: 15px 30px;
  margin: 10px 0;
  font-size: 1.2rem;
  border-radius: 50px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    background: #fff;
    color: #000;
    cursor: pointer;
  }
`;

export const NavIcon = styled.span`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;
