import React from 'react';
import {
  SectionContainer,
  Overlay,
  ContentWrapper,
  InfoSection,
  InfoTitle,
  ContactSection,
  ContactImage,
  ContactText,
  InfoItem,
  InfoIcon,
  InfoText,
  NavSection,
  NavButton,
  NavIcon
} from './styles.ts';
import Carousel from '../../images/carousel.jpg';
import Zap from '../../images/zap.png';
import {
  MdWeekend,
  MdCleaningServices,
  MdOutlineColorLens
} from 'react-icons/md';
import { GiGardeningShears, GiWaterDrop } from 'react-icons/gi';
import { FaBroom, FaTools } from 'react-icons/fa';
import * as Bs from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

export const SectionComponent: React.FC = () => {
  const navigate = useNavigate();

  return (
    <SectionContainer background={Carousel}>
      <Overlay />
      <ContentWrapper>
        <InfoSection>
          <InfoTitle>Conte Conosco</InfoTitle>
          <ContactSection>
            <ContactImage src={Zap} alt="Contato via Zap" />
            <ContactText>(11) 97193 8148</ContactText>
          </ContactSection>

          <InfoItem>
            <InfoIcon>
              <MdCleaningServices size={24} />
            </InfoIcon>
            <InfoText>Limpeza Residencial e Comercial</InfoText>
          </InfoItem>

          <InfoItem>
            <InfoIcon>
              <FaBroom size={24} />
            </InfoIcon>
            <InfoText>Limpeza Pós-obra</InfoText>
          </InfoItem>

          <InfoItem>
            <InfoIcon>
              <FaTools size={24} />
            </InfoIcon>
            <InfoText>Manutenção Predial</InfoText>
          </InfoItem>

          <InfoItem>
            <InfoIcon>
              <FaTools size={24} />
            </InfoIcon>
            <InfoText>Passadoria</InfoText>
          </InfoItem>

        </InfoSection>

        <NavSection>
          <NavButton onClick={() => navigate('/page/comercial')}>
            <NavIcon>
              <Bs.BsFillBuildingFill size={24} />
            </NavIcon>
            Comercial
          </NavButton>
          <NavButton onClick={() => navigate('/page/residencial')}>
            <NavIcon>
              <Bs.BsFillHouseFill size={24} />
            </NavIcon>
            Residencial
          </NavButton>
        </NavSection>
      </ContentWrapper>
    </SectionContainer>
  );
};
