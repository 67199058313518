import React, { useState, useEffect } from 'react';
import {
  ProductsContainer,
  ProductsContainerCards,
  ProductsContainerCardsImage,
  ProductsContainerCardsButton,
  CartDrawer,
  CartDrawerHeader,
  CartDrawerItem,
  CartDrawerFooter,
  SearchInput,
  CartItemsContainer,
  CartIconButton,
  CategoryFilterContainer,
  CategoryButton,
  PageTitle,
} from './styles.ts';
import { FaShoppingCart } from 'react-icons/fa';

interface Product {
  id: string;             // ID vem como string
  name: string;
  description?: string;
  category: string;
  price: string;          // Preço vem como string ("10.00")
  images: string[];       // Imagens é um array de URLs
}

export const ProductsComponent: React.FC = () => {
  // Estado para armazenar os produtos vindos da API
  const [products, setProducts] = useState<Product[]>([]);
  const [cart, setCart] = useState<Product[]>([]);
  const [isCartOpen, setCartOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [animateCart, setAnimateCart] = useState(false);

  // Chamada à função global getProducts para carregar os produtos
  useEffect(() => {
    if (window.Roktune && typeof window.Roktune.getProducts === 'function') {
      window.Roktune
        .getProducts()
        .then((data: Product[]) => {
          setProducts(data);
        })
        .catch((error) => console.error('Erro ao obter produtos:', error));
    }
  }, []);

  // Gerar dinamicamente as categorias únicas dos produtos (tudo em minúsculo)
  const uniqueCategories = [
    'all',
    ...Array.from(new Set(products.map((p) => p.category.toLowerCase())))
  ];

  // Filtra os produtos conforme a busca e a categoria selecionada
  // (comparando em minúsculas para evitar problemas de case-sensitive)
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (selectedCategory === 'all' || product.category.toLowerCase() === selectedCategory)
  );

  const addToCart = (product: Product) => {
    setCart((prev) => [...prev, product]);
    setAnimateCart(true);
  };

  useEffect(() => {
    if (animateCart) {
      const timer = setTimeout(() => setAnimateCart(false), 500);
      return () => clearTimeout(timer);
    }
  }, [animateCart]);

  const toggleCart = () => {
    setCartOpen(!isCartOpen);
  };

  // Calcula o total usando parseFloat, já que product.price é uma string
  const total = cart.reduce((acc, item) => acc + parseFloat(item.price), 0);
  const cartMessage = `Olá, estou interessado em solicitar informações sobre os seguintes produtos: ${cart
    .map((item) => item.name)
    .join(', ')}. Total: R$ ${total.toFixed(2)}`;
  const whatsappLink = `https://api.whatsapp.com/send/?phone=5511971938148&text=${encodeURIComponent(
    cartMessage
  )}&type=phone_number&app_absent=0`;

  return (
    <>
      <PageTitle>Nossos Produtos mais recentes</PageTitle>

      {/* Filtro por categoria (gerado dinamicamente) */}
      <CategoryFilterContainer>
        {uniqueCategories.map((category) => (
          <CategoryButton
            key={category}
            active={selectedCategory === category}
            onClick={() => setSelectedCategory(category)}
          >
            {category === 'all'
              ? 'Todos'
              : category.charAt(0).toUpperCase() + category.slice(1)}
          </CategoryButton>
        ))}
      </CategoryFilterContainer>

      {/* Ícone do Carrinho (abre/fecha o Drawer) */}
      <CartIconButton onClick={toggleCart} animate={animateCart}>
        <FaShoppingCart size={24} />
        {cart.length > 0 && <span>{cart.length}</span>}
      </CartIconButton>

      <ProductsContainer>
        {filteredProducts.map((product) => (
          <ProductsContainerCards key={product.id}>
            {/* Usa a primeira imagem do array de imagens */}
            <ProductsContainerCardsImage
              src={product.images[0]}
              alt={product.name}
            />
            <h3>{product.name}</h3>
            <p style={{ fontWeight: 'bold' }}>
              R$ {parseFloat(product.price).toFixed(2)}
            </p>
            <ProductsContainerCardsButton onClick={() => addToCart(product)}>
              Adicionar ao Carrinho
            </ProductsContainerCardsButton>
          </ProductsContainerCards>
        ))}
      </ProductsContainer>

      {/* Drawer do Carrinho */}
      <CartDrawer open={isCartOpen}>
        <CartDrawerHeader>
          <h2>Carrinho</h2>
          <button
            onClick={toggleCart}
            style={{
              background: 'transparent',
              border: 'none',
              fontSize: '20px',
              cursor: 'pointer',
            }}
          >
            X
          </button>
        </CartDrawerHeader>

        <CartItemsContainer>
          {cart.length === 0 ? (
            <p>Seu carrinho está vazio.</p>
          ) : (
            cart.map((item, index) => (
              <CartDrawerItem key={index}>
                <img
                  src={item.images[0]}
                  alt={item.name}
                  style={{
                    width: '50px',
                    height: '50px',
                    objectFit: 'cover',
                    borderRadius: '5px',
                  }}
                />
                <div style={{ marginLeft: '10px' }}>
                  <h4 style={{ margin: 0 }}>{item.name}</h4>
                  <p style={{ margin: 0 }}>R$ {parseFloat(item.price).toFixed(2)}</p>
                </div>
              </CartDrawerItem>
            ))
          )}
        </CartItemsContainer>

        <CartDrawerFooter>
          <h3>Total: R$ {total.toFixed(2)}</h3>
          {cart.length > 0 && (
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              <ProductsContainerCardsButton>
                Finalizar Compra
              </ProductsContainerCardsButton>
            </a>
          )}
        </CartDrawerFooter>
      </CartDrawer>
    </>
  );
};
