import styled, { keyframes } from 'styled-components';

export const ProductsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px 15%;
  background: #f7f9fc;
  max-height: calc(80vh - 250px);
  overflow-y: auto;
`;

export const ProductsContainerCards = styled.div`
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
  padding: 15px;

  &:hover {
    transform: translateY(-5px);
  }

  h3 {
    margin: 10px 0 5px;
  }

  p {
    margin: 5px 0;
  }
`;

export const ProductsContainerCardsImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
`;

export const ProductsContainerCardsButton = styled.button`
  background: #234480;
  color: #fff;
  border: none;
  padding: 10px 15px;
  margin-top: auto;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #1a3468;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  max-width: 400px;
  padding: 10px;
  margin: 20px auto;
  display: block;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

/* Ícone do Carrinho com animação de piscar */
const blinkAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
`;

export const CartIconButton = styled.button<{ animate: boolean }>`
  position: fixed;
  top: 20px;
  right: 20px;
  background: #234480;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  outline: none;
  animation: ${({ animate }) => (animate ? blinkAnimation : 'none')} 0.5s linear;

  & > span {
    position: absolute;
    top: -5px;
    right: -5px;
    background: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

/* Drawer do Carrinho */
export const CartDrawer = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  right: ${(props) => (props.open ? '0' : '-400px')};
  width: 350px;
  height: 100%;
  background: #fff;
  box-shadow: -4px 0 12px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const CartDrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
`;

export const PageTitle = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #234480; /* Escolha uma cor que combine com seu tema */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid #234480;
  display: inline-block;
  padding-bottom: 0.5rem;
  letter-spacing: 1px;
  font-family: 'Roboto', sans-serif; /* ou outra fonte que você esteja usando */
`;

export const ProductsWrapper = styled.div`
  /* Calcule a altura disponível subtraindo a altura de outros elementos fixos.
     Ajuste o valor (ex.: 250px) conforme o layout da sua página */
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  /* Caso queira manter o mesmo background e espaçamentos */
  background: #f7f9fc;
  padding: 20px 15%;
`;

export const CartItemsContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  max-height: 60vh;
  margin-top: 10px;
`;

export const CartDrawerItem = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
`;

export const CartDrawerFooter = styled.div`
  border-top: 1px solid #ddd;
  padding-top: 10px;
  text-align: center;
  position: sticky;
  bottom: 0;
  background: #fff;
  padding-bottom: 10px;
`;


/* Filtro por Categoria */
export const CategoryFilterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px auto;
  max-width: 800px;
`;

export const CategoryButton = styled.button<{ active: boolean }>`
  padding: 8px 16px;
  background: ${(props) => (props.active ? '#234480' : '#fff')};
  color: ${(props) => (props.active ? '#fff' : '#234480')};
  border: 1px solid #234480;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: #234480;
    color: #fff;
  }
`;
